<template>
  <div>
    <Navigation v-bind:Breadcrumb="[
      { name: 'Home', route: 'Home' },
      { name: 'Staff', route: 'Admin' },
      { name: 'Update Settings', route: 'Admin_Settings' },
    ]" />
    <div class="placeholder">
      <div class="error" v-for="error in errMsg" :key="error">{{ error }}</div>
      <div class="success" v-for="message in msg" :key="message">
        {{ message }}
      </div>
      <div class="row">
        <form class="form" v-on:submit.prevent="UpdateAppVersions">
          <div class="col-6">
            <label for="name">Android Version</label>
            <input type="text" class="input" v-model="appversions.android" placeholder="2.1" required />
          </div>
          <div class="col-6">
            <label for="name">iOS Version</label>
            <input type="text" class="input" v-model="appversions.ios" placeholder="60" required />
              <input type="submit" class="btn btn-green" value="Update" />
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Navigation from "@/components/Navigation.vue";
import { useStore } from "vuex";

export default {
  name: "Admin_GDPR",
  setup() {
    const store = useStore();

    return { store };
  },
  data() {
    return {
      msg: [],
      errMsg: [],
      appversions: {}
    };
  },
  methods: {
    GetVersions() {
      this.store.dispatch("Admin/Getappversions")
        .then((result) => {
          this.appversions = result.data
        }).catch((error)=> {
          this.errMsg = error.messages

        })
        

    },
    UpdateAppVersions() {
      this.store.dispatch("Admin/Updateappversions",this.appversions)
        .then((result) => {
          this.msg = result.messages
        }).catch((error)=> {
          this.errMsg = error.messages

        })

    }
  },
  computed: {},
  components: {
    Navigation,
  },
  mounted() {
    this.GetVersions()
  },
};
</script>

<style>
.error {
  height: auto;
  width: 100%;
  background: #e34343;
  padding: 10px;
  box-sizing: border-box;
  text-align: center;
  display: block;
  border-radius: 10px;
  font-size: 17px;
  color: #fff;
  border: 1px solid #c52f2f;
  margin-bottom: 5px;
}

.success {
  height: auto;
  width: 100%;
  background: #04a394;
  padding: 10px;
  box-sizing: border-box;
  text-align: center;
  display: block;
  border-radius: 10px;
  font-size: 17px;
  color: #fff;
  border: 1px solid #069486;
  margin-bottom: 5px;
}
</style>